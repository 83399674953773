import { useEffect, useState } from 'react';

const MOBILE_MAX_WIDTH = 1000;

export function useWindowSize() {
    let width;
    let height;
    if (typeof window !== 'undefined') {
        width = window.innerWidth;
        height = window.innerHeight;
    }
    const [size, setSize] = useState({ w: width, h: height });
    useEffect(() => {
        function onResize() {
            setSize({ w: window.innerWidth, h: window.innerHeight });
        }
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);
    return size;
}

export function useIsMobile() {
    const windowSize = useWindowSize();
    const isMobile = windowSize.w <= MOBILE_MAX_WIDTH;

    const [hasMounted, setHasMounted] = useState(false);
    useEffect(() => {
        setHasMounted(true);
    }, []);

    return isMobile && hasMounted;
}

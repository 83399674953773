import React from 'react';
import Layout from '../components/layout';
import { useIsMobile } from '../utils/hooks';
import * as styles from './privacy.module.css';

export default function Privacy() {
    const isMobile = useIsMobile();
    return (
        <Layout isMobile={isMobile} minimal={true}>
            <div className={styles.container}>
                <div className={styles.center}>
                    <h2>Privacy Policy</h2>
                    <p>
                        La presente Informativa sulla privacy descrive le prassi, tra cui le opzioni a disposizione,
                        relative alla raccolta, all'utilizzo e alla comunicazione di determinate informazioni, relativi
                        alla relazione dei dati personali dell’utente con BAUMANN.
                    </p>
                    <p>
                        Le disposizioni della presente Informativa si riferiscono a tutti i servizi di BAUMANN e a
                        eventuali servizi correlati. I termini che regolano l’utilizzo del Servizio BAUMANN sono
                        definiti nei Termini e condizioni d’uso che riportiamo integralmente quale allegato a questa
                        informativa.
                    </p>
                    <h4>1. Informativa sulla privacy</h4>
                    <p>
                        Ai sensi e per gli effetti delle previsioni di cui al Decreto Legislativo 196/2003 (“D. Lgs.
                        196/2003”), nonché del Regolamento UE 679/2016 in materia di General Data Protection Regulation,
                        recanti disposizioni in materia di protezione dei dati personali, BAUMANN informa il Cliente
                        che, con riferimento alle relazioni in essere ha raccolto e trattato, raccoglierà e tratterà
                        dati riferibili al Cliente definiti dal D. Lgs. 196/2003 e dal Regolamento UE 679/2016 come
                        “dati personali”.
                    </p>
                    <h4>2. Contatti</h4>
                    <p>
                        Per qualsiasi domanda relativa alla presente Informativa sulla privacy o all'utilizzo da parte
                        di BAUMANN dei dati personali del Cliente, di cookie e altre tecnologie analoghe, si invita a
                        contattare il Responsabile della Protezione dei Dati/Ufficio Privacy tramite email all'indirizzo
                        privacy@mybaumann.it. Il titolare del trattamento con riferimento ai tuoi dati personali è
                        BAUMANN s.r.l.
                    </p>
                    <h4>3. Utilizzo delle informazioni</h4>
                    <p>
                        BAUMANN utilizzerà le informazioni per fornire, analizzare, erogare, migliorare e personalizzare
                        i servizi e le attività di marketing, per gestire le segnalazioni degli abbonati, per elaborare
                        la registrazione, gli ordini e i pagamenti e per comunicare con il Cliente su tali e altri
                        argomenti. In particolare, i dati personali sono e saranno trattati per le seguenti finalità:
                    </p>
                    <ol>
                        <p>
                            (i) gestire gli adempimenti civilistici, fiscali e previdenziali in conformità alle
                            normative di riferimento e qualsivoglia ulteriore adempimento richiesto in ottemperanza del
                            Servizio BAUMANN;
                        </p>
                        <p>
                            (ii) proteggere i sistemi BAUMANN, prevenire le frodi e contribuire a tutelare la sicurezza
                            degli account;
                        </p>
                        <p>(iii) Per comunicare con il Cliente per scopi connessi al Servizio BAUMANN;</p>
                        <p>
                            (iv) analizzare e comprendere il nostro pubblico, migliorare il nostro servizio (compresa
                            l'esperienza dell'interfaccia utente e le prestazioni del servizio stesso) e la fornitura
                            del servizio;
                        </p>
                        <p>(v) includere il nominativo del Cliente tra le referenze della BAUMANN in elenchi, ecc</p>
                        <p>
                            La BAUMANN informa il Cliente che, nel caso in cui il Cliente si rifiuti di fornire i dati o
                            nel caso in cui il Cliente non presti il consenso al trattamento dei dati per le finalità
                            indicate ai punti (i), (ii) e (iii), la BAUMANN non potrà prestare i Servizi.
                        </p>
                    </ol>
                    <h4>4. Raccolta di informazioni</h4>
                    <p>BAUMANN raccoglie e conserva le seguenti informazioni comunicate dal Cliente manualmente:</p>
                    <ol>
                        <p>
                            (i) creazione dell’account: Nome e Cognome; Data di nascita, Città, Codice fiscale,
                            Telefono, Indirizzo Mail;
                        </p>
                        <p>
                            (ii) attivazione dell’abbonamento: Tipologia di Contabilità, indirizzo residenza e
                            domicilio, dati inerenti alle posizioni fiscali, i documenti di identità, le informazioni di
                            lavoro e professionali. Si tratta dei dati personali forniti dl Cliente o raccolti per
                            consentire l’iscrizione e l’utilizzo del Servizio BAUMANN nonché le iscrizioni presso gli
                            enti pubblici e le autorità finanziarie di riferimento. Vengono altresì richieste
                            informazioni sulla base di quanto necessario e richiesto dalle camere di commercio per
                            l’avvio delle attività. Alcuni dei dati personali che chiediamo di fornire sono necessari
                            per creare un account. L’utente ha inoltre la possibilità di fornirci ulteriori dati
                            personali al fine di rendere il proprio account più personalizzato.
                        </p>
                        <p>
                            (iii) dati di pagamento: informazioni sul tipo di abbonamento, tipo di carta di credito o di
                            debito, data di scadenza e alcune delle cifre del numero di carta;
                        </p>
                        <p>BAUMANN raccoglie e conserva le seguenti informazioni automaticamente:</p>
                        <p>
                            (i) Interazioni del Cliente: Informazioni sulle interazioni dell’utente con il Servizio,
                            comprendenti data e ora delle eventuali richieste effettuate, documenti caricati, richieste
                            di informazioni generate, contenuti video visionati e audio. Le interazioni con le email
                            mandate da BAUMANN, SMS e con i messaggi tramite notifiche push e canali di messaggistica
                            online;
                        </p>
                        <p>
                            (ii) ID o altri identificatori univoci dei dispositivi del Cliente, anche relativi ai
                            dispositivi di rete e a dispositivi compatibili con BAUMANN sulla rete Wi-Fi;
                        </p>
                        <p>
                            (iii) identificatori pubblicitari: identificatori di dispositivi reimpostabili, come quelli
                            presenti su dispositivi mobili, tablet e dispositivi di streaming multimediali che li
                            prevedono;
                        </p>
                        <p>
                            (iv) caratteristiche del dispositivo e software: tipo e configurazione del dispositivo,
                            informazioni relative alla connessione, compreso il tipo (Wi-Fi o cellulare), statistiche
                            sulla visualizzazione di pagine, fonti di provenienza (ad esempio, URL di riferimento),
                            indirizzo IP (che può indicarci la tua posizione generica), dati relativi al browser e
                            normali informazioni contenute nel registro del server web;
                        </p>
                        <p>
                            (v) Cookie: informazioni raccolte tramite l'utilizzo di cookie, web beacon e altre
                            tecnologie, compresi dati pubblicitari (quali le informazioni sulla disponibilità e
                            l'implementazione della pubblicità, l'URL del sito, la data e l'ora).
                        </p>
                    </ol>
                    <h4>5. Divulgazioni delle informazioni</h4>
                    <p>Il Cliente è reso edotto del fatto che i dati personali potranno essere comunicati a:</p>

                    <ol>
                        <p>
                            (i) Società del gruppo BAUMANN: potremo condividere le tue informazioni con le società del
                            gruppo BAUMANN, ove necessario per fornirti accesso ai nostri servizi; fornire assistenza;
                            prendere decisioni al fine di migliorare il servizio; sviluppare i contenuti; e per altri
                            fini indicati nella sezione "Utilizzo delle informazioni" della presente Informativa sulla
                            privacy;
                        </p>
                        <p>
                            (ii) Legittimi destinatari: legittimi destinatari di comunicazioni imposte dalla legge o da
                            un regolamento (ad esempio: autorità pubbliche);
                        </p>
                        <p>
                            (iii) Fornitori di Servizi: soggetti terzi che collaborano con la BAUMANN nell’adempimento
                            dei Servizi; ad esempio, Fornitori di servizi per attività di consulenza fiscale, servizi di
                            informazione finanziaria, marketing, comunicazioni, sicurezza, infrastrutture e servizi
                            informatici, per customizzare, personalizzare e ottimizzare il servizio, per fornire
                            informazioni su conto bancario o sul saldo, per gestire operazioni con carta di credito e
                            altri metodi di pagamento, per il Servizio clienti, per analizzare e sviluppare i dati ed
                            elaborare e condurre indagini tra i consumatori. I Fornitori di servizi sono da noi
                            autorizzati a utilizzare o divulgare i tuoi dati personali per il solo fine della
                            prestazione dei loro servizi (incluso il mantenimento e il miglioramento dei propri
                            servizi);
                        </p>
                        <p>
                            (iv) Cessioni: in relazione a qualsiasi riorganizzazione, ristrutturazione, fusione o altra
                            cessione d'azienda, ci riserviamo il diritto di trasferire informazioni, compresi i dati
                            personali, a condizione che la parte ricevente acconsenta a trattare i tuoi dati personali
                            in linea con la nostra Informativa sulla privacy.
                        </p>
                    </ol>
                    <h4>6. Diritti e preferenze</h4>
                    <p>
                        Il “Regolamento generale sulla protezione dei dati o “GDPR” (General Data Protection
                        Regulation), conferisce determinati diritti alle persone fisiche in relazione ai loro dati
                        personali. Nella misura in cui sono disponibili e ad eccezione di quanto disposto dalla legge
                        vigente, i diritti concessi sono i seguenti: chiedere la conferma dell’esistenza o meno di
                        propri dati personali;
                    </p>
                    <ol>
                        <p>
                            (i) ottenere le indicazioni circa le finalità del trattamento, le categorie dei dati
                            personali, i destinatari o le categorie di destinatari a cui i dati personali sono stati o
                            saranno comunicati e, quando possibile, il periodo di conservazione;
                        </p>
                        <p>(ii) ottenere la rettifica e la cancellazione dei dati;</p>
                        <p>(iii) ottenere la limitazione del trattamento;</p>
                        <p>
                            (iv) ottenere la portabilità dei dati, ossia riceverli da un titolare del trattamento, in un
                            formato strutturato, di uso comune e leggibile da dispositivo automatico, e trasmetterli ad
                            un altro titolare del trattamento senza impedimenti;
                        </p>
                        <p>
                            (v) opporsi al trattamento in qualsiasi momento ed anche nel caso di trattamento per
                            finalità di marketing diretto;
                        </p>
                        <p>
                            (vi) opporsi ad un processo decisionale automatizzato relativo alle persone fisiche,
                            compresa la profilazione;
                        </p>
                        <p>
                            (vii) chiedere al titolare del trattamento l’accesso ai dati personali e la rettifica o la
                            cancellazione degli stessi o la limitazione del trattamento che lo riguardano o di opporsi
                            al loro trattamento, oltre al diritto alla portabilità dei dati;
                        </p>
                        <p>
                            (viii) revocare il consenso in qualsiasi momento senza pregiudicare la liceità del
                            trattamento basata sul consenso prestato prima della revoca;
                        </p>
                        <p>(ix) proporre reclamo a un’autorità di controllo;</p>
                        <p>
                            (x) il diritto di ottenere la documentazione custodita dalla BAUMANN. che riguardi il
                            Cliente;
                        </p>
                        <p>
                            (xi) il diritto di ottenere la distruzione, il blocco, l’aggiornamento, la modifica o
                            l’integrazione dei documenti così come di richiedere che i terzi che abbiano ricevuto i dati
                            facciano lo stesso;
                        </p>
                        <p>
                            (xii) il diritto di venire informato nel caso in cui i dati personali dello stesso siano
                            stati legittimamente utilizzati per finalità pubblicitarie e di marketing.
                        </p>
                    </ol>
                    <p>
                        Fatto salvo quanto sopra, BAUMANN. potrà trattenere copia della documentazione contenente dati
                        personali del Cliente per scopi di archiviazione o per le revisioni nell’ambito dei programmi di
                        controllo qualità.
                    </p>
                    <h4>7. Modifiche all’informativa sulla privacy</h4>
                    <p>
                        BAUMANN provvederà ad aggiornare periodicamente la presente Informativa sulla privacy a seguito
                        di modifiche dei requisiti normativi, operativi e di legge. In tal caso BAUMANN darà notifica di
                        tali modifiche (e della relativa entrata in vigore) ai sensi di legge. L'utilizzo del servizio
                        BAUMANN in seguito all'entrata in vigore di tali modifiche equivarrà al riconoscimento e (quando
                        applicabile) all'accettazione delle stesse. Qualora il Cliente non volesse riconoscere o
                        accettare le modifiche della presente Informativa sulla privacy, portà disdire il tuo
                        abbonamento al servizio BAUMANN. Per verificare la data della più recente modifica alla presente
                        Informativa sulla privacy, ti invitiamo a consultare la sezione "Ultimo aggiornamento" che
                        segue.
                    </p>
                    <p>Ultimo aggiornamento: 1 gennaio 2022</p>
                </div>
            </div>
        </Layout>
    );
}
